import 'primereact/resources/themes/saga-blue/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'


//import 'primereact'

import { Avatar } from 'primereact'
import { Button } from 'primereact/button'

function App() {
  const colors = {
    youtube: 'red',
    twitter: 'blue',
    github: 'green',
    substack: 'orange'
  }



  const data = {
    name: "Jangle",
    image: require("./jangle-logo.png"),
    bio: "DeFi Analyst. On-chain sleuth. I research cryptoeconomic systems.",
    links: [
      {
        name: "Twitter",
        url: "https://twitter.com/@defi_jangle",
        icon: "twitter"
      },
      {
        name: "Substack",
        url: "https://defijangle.substack.com/",
        icon: "substack"
      },
      {
        name: "YouTube",
        url: "https://www.youtube.com/defijangle",
        icon: "youtube"
      },
      {
        name: "GitHub",
        url: "https://github.com/jfangle",
        icon: "github",
      },
    ],
  };


  //substack is not an icon in primeflex so I have to use bookmark-fill. I couldn't asign a two word field in the colors function so this was my work around
  function checkIfSubstackIcon(icon){
    if(icon === 'substack'){
      return 'bookmark-fill';
    }
    else{
      return icon;
    }
  }

  return (

    <div className='m-5'>

      <div className="flex justify-content-center align-items-center">
        <Avatar image={data.image} style={{width:'120px', height:'120px'}} shape="circle"></Avatar>
      </div>

      <div className="flex justify-content-center align-items-center">
        <h1 className="text-white">{data.name}</h1>
      </div>

      <div className="flex justify-content-center align-items-center text-white">
        <p>{data.bio}</p>
      </div>

      <div className="flex justify-content-center align-items-center">

        <div className='flex 	flex-column'>
          {data.links.map((link) => (
            
            <a href={link.url} target="_blank" rel="noopener noreferrer">
              <Button
                className="m-2 p-3.5 p-button-outlined bg-white ui-button-raised"
                style={{ color: colors[link.icon], minWidth:"490px",}}
              >
                <i className={`pi pi-${checkIfSubstackIcon(link.icon)} px-3`}></i>
                <span style={{color:'black',marginLeft:'150px'}}>{link.name}</span>
              
              </Button>
            </a>

            

            

          ))}
        </div>

      </div>

    </div>
  );
}

export default App;
